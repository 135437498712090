import {
  Autocomplete,
  Button,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material"
import { useState } from "react"

type Filters = {
  page: number
  limit: number
  filterCourse: string | null
  filterActor: string | null
  filterAction: string | null
  filterSince: string | null
  filterBefore: string | null
  search: string | null
}

type ActionLogFilterProps = {
  filters: Filters
  onFilterChange: (filter: any, checkChanged?: boolean) => void
  options: any
  courseInput: string | undefined
  setCourseInput: (course: string) => void
  isFiltered: boolean
  setSearchInput: (input: string) => void
  initialLoad: boolean
  isLoadingHistory: boolean
}

/**
 * Component for displaying input fields for filtering audit history entries
 * @param props - Component props
 */
const ActionLogFilters = (props: ActionLogFilterProps) => {
  const [whoInput, setWhoInput] = useState("")
  const [actionInput, setActionInput] = useState("")

  const {
    filters,
    onFilterChange,
    options,
    courseInput,
    setCourseInput,
    isFiltered,
    setSearchInput,
    initialLoad,
    isLoadingHistory,
  } = props

  const autocompleteStyles = {
    sx: {
      flex: "1 1 0",
    },
  }

  const displayLoading = !initialLoad && isLoadingHistory

  return (
    <Stack width="100%">
      <Stack width="100%" direction="row" gap={2} alignItems="center">
        <Autocomplete
          disablePortal
          id="actor"
          value={filters.filterActor}
          inputValue={whoInput}
          onInputChange={(event, newValue) => setWhoInput(newValue)}
          onChange={(event, newValue) =>
            onFilterChange({ filterActor: newValue })
          }
          options={options["filterActor"]}
          renderInput={(params) => <TextField {...params} label="Actor" />}
          size="small"
          {...autocompleteStyles}
        />
        <Autocomplete
          disablePortal
          id="action"
          value={filters.filterAction}
          inputValue={actionInput}
          onChange={(event, newValue) =>
            onFilterChange({ page: 0, filterAction: newValue })
          }
          onInputChange={(event, newValue) => setActionInput(newValue)}
          options={options["filterAction"]}
          renderInput={(params) => <TextField {...params} label="Action" />}
          size="small"
          {...autocompleteStyles}
        />
        <Autocomplete
          disablePortal
          //selected course
          value={filters.filterCourse}
          onChange={(event, newValue) => {
            onFilterChange({ page: 0, filterCourse: newValue })
          }}
          //user input
          inputValue={courseInput}
          onInputChange={(event, newValue) => {
            setCourseInput(newValue)
          }}
          id="combo-box-demo"
          options={options["filterCourse"]}
          isOptionEqualToValue={(option: any, value: any) => {
            //LD-1062
            if (!value) {
              //value is undefined, we don't match anything because there are no undefined options
              return true
            }
            if (option && option.title === value.title) {
              //found a match
              return true
            }
            //no match found
            return false
          }}
          getOptionLabel={(option: any) => (option.title ? option.title : "")}
          renderInput={(params) => <TextField {...params} label="Course" />}
          size="small"
          {...autocompleteStyles}
        />
        <Button
          variant="outlined"
          disabled={initialLoad || isFiltered}
          color="primary"
          onClick={(e) => {
            e.preventDefault()
            setSearchInput("")
            setWhoInput("")
            setActionInput("")
            setCourseInput("")
            onFilterChange(null)
          }}
        >
          Clear Filters
        </Button>
      </Stack>
      <Stack height="8px" marginTop={2}>
        {displayLoading && <LinearProgress />}
      </Stack>
    </Stack>
  )
}

export default ActionLogFilters
