import axios, { gateway } from "./api/axios"
import * as errors from "./utilities/errors"
import { Auth } from "@aws-amplify/auth"
import { convertObjectToQuery } from "./utilities/transformers"
import { EXPORT_API_ENPOINT } from "./api/exportEndpoint"

export { default as addComment } from "./api/addComment"
export { default as editComment } from "./api/editComment"
export { default as deleteComment } from "./api/deleteComment"
export { default as resolveComment } from "./api/resolveComment"
export { replyToComment } from "./api/replyToComment"
export { editCommentReply } from "./api/editCommentReply"
export { deleteCommentReply } from "./api/deleteCommentReply"
export { default as fetchAsyncJobs } from "./api/fetchAsyncJobs"
export { default as updateShareSettings } from "./api/updateShareSettings"
export { default as uploadCourseDocument } from "./api/uploadCourseDocument"
export { default as fetchDocumentHeadings } from "./api/fetchDocumentHeadings"
export { default as fetchDocumentFromUrl } from "./api/fetchDocumentFromUrl"
export { default as createTemplate } from "./api/createTemplate"
export { default as verifyEmail } from "./api/verifyEmail"
export { default as giveCourse } from "./api/giveCourse"
export { default as fetchPosts } from "./api/fetchPosts"
export { default as startExport } from "./api/startExport"
export { default as createExportZip } from "./api/createExportZip"
export { default as createExportPreview } from "./api/createExportPreview"
export { default as fetchExportStatus } from "./api/fetchExportStatus"
export { default as startImport } from "./api/startImport"
export { default as importFilesUploaded } from "./api/importFilesUploaded"
export { default as fetchImportJobs } from "./api/fetchImportJobs"
export { default as fetchImportJob } from "./api/fetchImportJob"
export { default as cancelImportJob } from "./api/cancelImportJob"
export { default as fetchImportSourceHeadings } from "./api/fetchImportSourceHeadings"
export { default as fetchLmsCourse } from "./api/fetchLmsCourse"
export { saveCourse } from "./api/saveCourse"

/** Retrieve the current user authentication token. */
export async function getToken() {
  const session = await Auth.currentSession()
  return session.getAccessToken().getJwtToken()
}

/**
 * Find a single course
 * @param params search params
 */
export function find(params) {
  return axios.post("/find/", null, { params })
}

/**
 * Replace a single course
 * @param params search params
 */
export function replace(params) {
  return axios.post("/replace/", { ...params })
}

/**
 * Deletes a single course
 * @param course_id a course id
 */
export function deleteCourse(course_id) {
  return axios.delete(`/courses/${course_id}/`)
}

export function restoreCourse(course_id) {
  return axios.post(`/courses/${course_id}/restore`)
}

/**
 * Duplicates a single course
 * @param course_id a course id
 */
export function duplicateCourse(course_id) {
  return axios.post(`/courses/${course_id}/duplicate`)
}

/**
 * Cancel course creation
 * @param course_id course id
 */
export function cancelCourseCreation(course_id) {
  return axios.post(`/courses/${course_id}/cancel`)
}

export function loadCourse(course_id) {
  return axios.get(`/courses/${course_id}`)
}

/**
 * Rename a course and give it a new title
 * @param {*} course_id - course identifier
 * @param {*} title - the new title for the course
 * @returns Promise<AxiosResponse<any>>
 */
export function renameCourse(course_id, title) {
  return axios
    .post(`/courses/${course_id}/rename`, {
      title: title,
    })
    .catch((error) => {
      console.log(error)
      if (!error.response) {
        throw new errors.OfflineError()
      }
      throw error
    })
}

/**
 * Get all courses
 */
export function getCourses() {
  return axios.get(`/courses/all`)
}

/**
 * Get all trashed courses
 */
export function getTrashedCourses() {
  return axios.get(`/courses/trash/`)
}

/**
 * Get history details for a course
 * @param id a course id
 */
export function getHistoryDetails(id) {
  return axios.get(`/history/${id}`)
}

/**
 * Get history for a course
 * @param params search params
 */
export function getHistory(params) {
  const query = `?${convertObjectToQuery(params, true)}`
  return axios.get(`/history/${query}`)
}

export function getComments(course_id) {
  return axios.get(`/courses/${course_id}/comments`)
}

/**
 * Export a course and course description asset as a zip file.
 * This starts will return an async job response,
 * because the export is a multi-step process.
 *
 *   let docRequest = {
 *       modalities: ["Instructor Led"],
 *       audiences: ["Customer"],
 *       skill_level:
 *         "Introductory - Basic level concepts requiring little or no prerequisite knowledge",
 *       high_level_goal: "high level goal text",
 *       prerequisites: ["How to build a Sales Leaderboard"],
 *       duration: 1020,
 *   }
 *
 * The server will respond with an async response which includes and async_id
 * which is used for polling in exportCheckAsyncJob to determine
 * when the job is complete.
 *
 * @param {*} course_id - the course you want to export
 * @param {*} docRequest - specification for what you want to download
 * @returns the axios request
 */
export function exportCourseAssets(course_id, docRequest) {
  return axios.post(`/export/${course_id}/`, { ...docRequest })
}

/**
 * Send any new files that have been uploaded as branding overrides to the server,
 * where they will be saved and have URLs generated for them to be accessed later
 * at the export endpoint.
 * @param changes - All changed branding properties
 */
export function createBrandingOverrides(changes) {
  const data = new FormData()

  for (const brandingProperty in changes) {
    data.append(brandingProperty, changes[brandingProperty])
  }
  return axios.post(`/export/branding`, data).then((response) => response.data)
}

/**
 * Publish a course to an LMS.
 * This will return an async job response,
 * because the export is a multi-step process.
 *
 *   let docRequest = {
 *       modalities: ["Instructor Led"],
 *       audiences: ["Customer"],
 *       skill_level:
 *         "Introductory - Basic level concepts requiring little or no prerequisite knowledge",
 *       high_level_goal: "high level goal text",
 *       prerequisites: ["How to build a Sales Leaderboard"],
 *       duration: 1020,
 *   }
 *
 * The server will respond with an async response which includes and async_id
 * which is used for polling in exportCheckAsyncJob to determine
 * when the job is complete.
 *
 * @param {*} courseId - the course you want to export
 * @param {*} docRequest - specification for what you want to download
 * @returns the axios request
 */
export function publishCourse(courseId, docRequest) {
  const lmsName = docRequest["format"]
  return axios.post(`/publish/${lmsName}/${courseId}/`, { ...docRequest })
}

/**
 * See if an LMS publisher is available
 *
 * @param lmsName - name of LMS to check, scormcloud or skilljar
 * @returns the axios request
 */
export async function checkLms(lmsName) {
  try {
    const result = await axios.get(`/lms/${lmsName}`)
    return result.status === 200
  } catch {
    return false
  }
}

/**
 * Command to request the generation of smaller courses from a larger course.
 * There are currently 3 types of microlearning (chunk, condense and concept).
 * Each microlearning type can have its own POST body parameters
 *
 * @param {*} type microlearning to create: 'CHUNK', 'CONDENSE' OR CONCEPT
 * @param {*} courseId the course to create microlearning from
 * @param {*} selectedConcepts optional. Applies to concept microlearning. Array of concepts
 * @param format
 * @returns the axios request
 */
export function createMicrolearning(type, courseId, selectedConcepts, format) {
  return axios.post("/microlearning/", {
    type: type,
    course_id: courseId,
    concepts: JSON.stringify(selectedConcepts),
    format,
  })
}

/**
 * For a given async job, check the status.
 *
 * The status steps are defined by the type of job.
 *    upload: UploadAsyncStatusEnum
 *    export: ExportAsyncStatusEnum
 *    microlearning: MicrolearningAsyncStatusEnum
 *
 * When the async processing is complete, this call returns a status of 'complete'
 * and the data for the handling completion is returned.
 * For example, with export, a url to download the export zip file is provided
 *
 * If this async job has failed, the status will return as 'failed'
 *
 * @param {*} async_id
 * @returns the async job status plus data
 */
export function checkAsyncJob(async_id) {
  return axios.get(`/jobs/${async_id}/`)
}

/**
 * Download a given url and then save it to the user's computer
 * @param {string} url - the web url for the file (e.g. zip file)
 * @param {string} filename - file to save to
 */
export function downloadFile(url, filename = null) {
  //get the filename from the end of the url
  if (filename == null) {
    filename = url.split("/").pop()
  }

  axios
    .get(url, {
      responseType: "blob",
      withCredentials: true,
    })
    .then((response) => {
      //trigger file download in the browser
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", filename)
      document.body.appendChild(link)
      link.click()
    })
}

/**
 * Download a .json file from a given filename
 * @param {*} folder the data path for the .json file
 * @param {*} filename the identifier for the file
 * @returns axios request
 */
export function downloadJSON(folder, filename) {
  return axios.get(`/data/${folder}/${filename}`)
}

export function getVideoDuration(url) {
  return axios.get(`/video_duration/${url}`)
}

/**
 * Concept Microlearning
 * Get the knowledge concepts in a course
 * These concepts are used generate smaller courses from the larger course
 * using natural language processing
 *
 * @param {*} course_id - the course to extract knowledge concepts from
 *
 * @returns the axios request - this will include an async job id in the response
 */
export function getMicroLearningConcepts(course_id) {
  return axios.get(`/courses/${course_id}/microlearning-concepts`)
}

export function downloadMicrolearning(course_id, type) {
  return axios.post(
    `/courses/${course_id}/microlearning/`,
    { type },
    {
      responseType: "blob",
    }
  )
}

/** Gets the latest number of intelligent updates remaining */
export async function getIntelligentUpdateCount(course_id) {
  const res = await axios.get(`/intelligent_updates`, {
    params: { course: course_id, count_only: "true" },
  })

  return res.data.num_updates
}

/**
 * Upload an image for Froala.
 *
 * @returns The url of the uploaded image.
 */
export async function uploadFroalaImage(file) {
  const data = new FormData()

  data.append("file", file)

  const result = await axios.post("/files/froala", data)
  return result.data.link
}

/**
 * Find content similar to a specific section within the same course.
 *
 * @param {string} course_html - The HTML content of the course.
 * @param {string} section_id - The ID of the section for which to find similar content.
 *
 * @returns {Promise} - A promise that resolves to an object containing similar sections.
 * The structure of the object is as follows:
 * {
 *  "similar_content": [
 *   {
 *    "id": string, // The ID of the similar section
 *    "title": string, // The title of the similar section
 *    "similarity": percentage // The similarity percentage of the content
 *   }
 *  ]
 * }
 *
 */
export async function findSimilarContent(course_html, section_id) {
  const res = await axios.post("/find_similar_content", {
    course_html,
    section_id,
  })
  return res.data
}

/**
 * Merge similar content to a given section of a course.
 *
 * @param {string} course_html - The HTML content of the course.
 * @param {string} target_section_id - The ID of the section to merge into.
 * @param {string[]} source_section_ids - The IDs of the sections to merge from.
 *
 * @returns {Promise} - A promise that resolves to an object containing the HTML of the merged section.
 * The structure of the object is as follows:
 * {
 *  "section_html": string // HTML of the merged section
 * }
 *
 * or
 * {
 *  "error_message": string // Error message if the merge failed
 * }
 *
 */
export async function mergeSimilarContent(
  course_html,
  target_section_id,
  source_section_ids
) {
  const res = await axios.post("/merge_similar_content", {
    course_html,
    target_section_id,
    source_section_ids: source_section_ids.join(","),
  })
  return res.data
}

/**
 * Generate alt text for a specific image.
 *
 * @param {string} imageUrl - The URL of the image.
 *
 * @returns {Promise} - A promise that resolves to a { alt_text: string } object.
 */
export async function generateAltText(imageUrl) {
  const res = await axios.post("/generate_alt_text", {
    image_url: imageUrl,
  })
  return res.data
}

/**
 * Rewrite content using the rewriteContent function.
 *
 * @param {string} html - The HTML content to rewrite.
 * @param {Object} options - The options for rewriting the content.
 * @param {string} options.tone - The optional tone to apply to the rewritten content.
 * @param {string} [options.structure] - The structure to apply to the rewritten content, if specified.
 * @param {string} options.length - The length of the rewritten content, if specified.
 * @param {string} options.grade_level - The optional grade level to apply to the rewritten content.
 * @param {string} options.custom_instructions - Any custom instructions for rewriting the content.
 *
 * @returns {Promise} - A promise that resolves to an object containing the rewritten content.
 * The structure of the object is as follows:
 * {
 *  "content": string // The rewritten content
 * }
 * or
 * {
 *  "error_message": string // Error message if the rewrite failed
 * }
 */
export async function rewriteContent(
  html,
  { tone, structure, length, grade_level, custom_instructions } = {}
) {
  const res = await axios.post("/rewrite_content", {
    html,
    tone,
    structure,
    length,
    grade_level,
    custom_instructions,
  })
  return res.data
}

/**
 * Generate a component from the provided HTML.
 *
 * @param {string} html - The HTML content to generate the component from.
 * @param {"flip-card-grid" | "process" | "tabs" | "styled-list" | "callout-box" | "categories" | "labeled-image" } componentType - The type of component to generate.
 *
 * @returns {Promise} - A promise that resolves to an object containing the generated component.
 * The structure of the object is as follows:
 * {
 *  "content": string // The generated component
 * }
 * or
 * {
 *  "error_message": string // Error message if the generation failed
 * }
 */
export async function generateComponent(html, componentType) {
  const res = await axios.post("/generate_component", {
    html,
    component_type: componentType,
  })
  return res.data
}

/**
 * Download a translation sheet for a course and language.
 *
 * @param {string} tenantId - The ID of the tenant.
 * @param {string} courseId - The ID of the course.
 * @param {string} translationLanguageCode - The target language code for translation.
 * @param {string} courseHtml - The HTML content of the course.
 * @param {string|null} summaryHtml - The HTML content of the course summary.
 * @returns {Promise<boolean>} - A promise that resolves when the file download is initiated or needs to be retried. True if the file download is initiated, false if the file download needs to be retried.
 * @throws {Error} - If the request fails or returns an error.
 */
export async function downloadTranslationSheet(
  tenantId,
  courseId,
  translationLanguageCode,
  courseHtml,
  summaryHtml
) {
  try {
    const data = {
      tenant_id: tenantId,
      course_id: courseId,
      translation_language_code: translationLanguageCode,
      course_html: courseHtml,
      summary_html: summaryHtml,
    }

    const response = await gateway.post(
      EXPORT_API_ENPOINT + "translation-sheet/download",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
        baseURL: "/",
      }
    )

    // API Gateway returns 503 in case of a timeout caused by a cold start of Lambda
    if (response.status === 202 || response.status === 503) {
      // The translation sheet is still being processed
      return false
    }

    const { filename, content, media_type } = response.data

    // Decode the Base64-encoded content
    const binaryContent = atob(content)
    const uint8Array = new Uint8Array(binaryContent.length)
    for (let i = 0; i < binaryContent.length; i++) {
      uint8Array[i] = binaryContent.charCodeAt(i)
    }

    const blob = new Blob([uint8Array], { type: media_type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || "Failed to download translation sheet"
      )
    }
    throw error
  }
  return true
}

/**
 * Upload a translation sheet for a course.
 *
 * @param {string} tenantId - The ID of the tenant.
 * @param {string} courseId - The ID of the course.
 * @param {File} file - The translation sheet file to upload.
 * @param {string} translationLanguageCode - The target language code for translation.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 * @throws {Error} - If the request fails or returns an error.
 */
export async function uploadTranslationSheet(
  tenantId,
  courseId,
  translationLanguageCode,
  file
) {
  try {
    // Read the file as a Base64 string
    const fileContent = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => resolve(event.target.result.split(",")[1])
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })

    const response = await gateway.post(
      EXPORT_API_ENPOINT + "translation-sheet/upload",
      {
        tenant_id: tenantId,
        course_id: courseId,
        translation_language_code: translationLanguageCode,
        file_content: fileContent,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        baseURL: "/",
      }
    )

    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.detail || "Failed to upload translation sheet"
      )
    }
    throw error
  }
}

/**
 * Get a preview of the translated course content.
 *
 * @param {string} tenantId - The ID of the tenant.
 * @param {string} courseId - The ID of the course.
 * @param {string} courseHtml - The HTML content of the course.
 * @param {string} languageCode - The language code for the translation.
 * @returns {Promise<string|null>} - A promise that resolves with the translated HTML preview content
 * or null if the preview is not available yet and should be retried.
 * @throws {Error} - If the request fails or returns an error.
 */
export async function getTranslatedPreview(
  tenantId,
  courseId,
  courseHtml,
  languageCode
) {
  try {
    const response = await gateway.post(
      EXPORT_API_ENPOINT + `translation-preview`,
      {
        tenant_id: tenantId,
        course_id: courseId,
        translation_language_code: languageCode,
        course_html: courseHtml,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        baseURL: "/",
      }
    )

    return response.data.content
  } catch (error) {
    // API Gateway returns 503 in case of a timeout caused by a cold start of Lambda
    if (
      error.response &&
      (error.response.status === 202 || error.response.status === 503)
    ) {
      return null
    }
    if (error.response) {
      throw new Error(
        error.response.data.detail || "Failed to get translated preview"
      )
    }
    throw error
  }
}

/**
 * Load the translation rules for a specific tenant.
 *
 * @param {string} tenantId - The ID of the tenant.
 * @returns {Promise<TranslationRuleData>} - A promise that resolves with the translation rules for the tenant.
 * @throws {Error} - If the request fails or returns an error.
 */
export async function loadTranslationRules(tenantId) {
  try {
    const response = await gateway.get(
      `${EXPORT_API_ENPOINT}translation-rules/${tenantId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        baseURL: "/",
      }
    )
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.detail || "Failed to get translation rules"
      )
    }
    throw error
  }
}

/**
 * Save the translation rules for a specific tenant.
 *
 * @param {string} tenantId - The ID of the tenant.
 * @param {TranslationRuleData} translationRules - The translation rules to set.
 * @returns {Promise<Object>} - A promise that resolves with a success message.
 * @throws {Error} - If the request fails or returns an error.
 */
export async function saveTranslationRules(tenantId, translationRules) {
  try {
    const response = await gateway.post(
      `${EXPORT_API_ENPOINT}translation-rules/${tenantId}`,
      translationRules,
      {
        headers: {
          "Content-Type": "application/json",
        },
        baseURL: "/",
      }
    )
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.detail || "Failed to set translation rules"
      )
    }
    throw error
  }
}
