import {
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
} from "@mui/material"
import {
  formatDate,
  isoStringToRelativeTime,
} from "../../../../../utilities/transformers"
import { CallMade } from "@mui/icons-material"
import ActionLogDetails from "./ActionLogDetails"
import { dataFilterAction } from "../../../../../hooks/useAuditHistory"
import useActionLogRecordData from "../hooks/useActionLogRecordData"

type ActionLogRecordProps = {
  record: any
  onSelectRecord: (id: string) => void
}

/**
 * Component for display a single action log item
 * @param props - Component props
 */
const ActionLogRecord = (props: ActionLogRecordProps) => {
  const { record, onSelectRecord } = props

  const { open, setOpen, loadingDetails, data } = useActionLogRecordData(record)

  const selectedAction = dataFilterAction.find(
    (action) => action.id === record.action_type
  )

  const cellStyleProps = {
    sx: {
      borderBottom: "none",
    },
  }

  return (
    <>
      <TableRow
        sx={{ cursor: "pointer", width: "100%" }}
        hover
        role="checkbox"
        key={record.history_id}
        onClick={() => setOpen((prev) => !prev)}
      >
        <TableCell {...cellStyleProps}>{record.course_title}</TableCell>
        <TableCell {...cellStyleProps}>{selectedAction?.label ?? ""}</TableCell>
        <TableCell {...cellStyleProps}>{record.username}</TableCell>
        <TableCell {...cellStyleProps}>
          <Tooltip enterDelay={1000} title={formatDate(record.recorded_at)}>
            <p>{isoStringToRelativeTime(record.recorded_at)}</p>
          </Tooltip>
        </TableCell>
        <TableCell {...cellStyleProps}>
          <Tooltip title="Action Details">
            <IconButton onClick={() => onSelectRecord(record.history_id)}>
              <CallMade />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow sx={{ width: "100%" }}>
        <TableCell
          style={{ padding: 0 }}
          colSpan={7}
          sx={(theme: Theme) => ({
            backgroundColor: theme.scheme.surfaceContainerHigh,
            width: "100%",
          })}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {loadingDetails ? (
              <Stack
                justifyContent="center"
                alignItems="center"
                height="100%"
                padding={2}
              >
                <CircularProgress />
              </Stack>
            ) : (
              data && (
                <Stack width="100%" padding={2}>
                  <ActionLogDetails
                    selectedAction={selectedAction}
                    onSelectRecord={() => onSelectRecord(record.history_id)}
                    data={data}
                    inlineView
                  />
                </Stack>
              )
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ActionLogRecord
