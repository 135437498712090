import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import mixpanel from "mixpanel-browser"
import { useAuth } from "../contexts/authorization"

const navEvents: { [key: string]: string } = {
  "/": "Home View",
  "/create-course": "Create Course View",
  "/creating-course": "Creating Course View",
  "/intelligent-update": "Intelligent Update View",
  "/microlearning": "Microlearning View",
  "/editor": "Editor View",
  "/editor/": "Editor View",
  "/tools": "Tools View",
  "/help": "Help View",
  "/export": "Export View",
  "/history": "History View",
  "/find": "Find and Replace View",
  "/share": "Share Course View",
  "/give": "Give Course View",
  "/trash": "Trash View",
  "/branding": "Branding View",
  "/translation-rules": "Translation View",
  "/login": "Login View",
}

export enum ButtonEvents {
  CreateCourse = "Create Course Clicked",
  TransformAction = "Transform Action Clicked",
  ReviewedAction = "Reviewed Action Clicked",
  PreviewCourse = "Preview Course Clicked",
  ExportCourse = "Export Course Clicked",
  MicrolearningExportCourse = "Microlearning Export Course Clicked",
  MicrolearningPreviewCourse = "Microlearning Preview Course Clicked",
  ApplyIntelligentUpdate = "Apply Intelligent Update Clicked",
  IgnoreIntelligentUpdate = "Ignore Intelligent Update Clicked",
}

interface Course {
  id: string
  tenantID: string
  title: string
}

/**
 * Tracks page views using Mixpanel whenever the user navigates to a new page.
 */
export function useMixpanelNavigationTracker(): void {
  const history = useHistory()
  const location = useLocation()
  const { user }: any = useAuth()

  useEffect(() => {
    let pageEvent = navEvents[location.pathname] || "Page View"

    mixpanel.track(pageEvent, {
      page: location.pathname,
      search: location.search,
      userID: user?.id || "Anonymous",
    })

    const unlisten = history.listen((newLocation) => {
      let newPageEvent = navEvents[newLocation.pathname] || "Page View"
      mixpanel.track(newPageEvent, {
        page: newLocation.pathname,
        search: newLocation.search,
      })
    })

    return () => {
      unlisten()
    }
  }, [history, location, user])
}

/**
 * Tracks a button click using Mixpanel.
 *
 * @param {string} buttonName - The name of the button being tracked.
 * @returns - A function that tracks the button click event.
 */
export function useMixpanelButtonTracker(buttonName: string) {
  const { user }: any = useAuth()
  /**
   *
   */
  const trackButtonClick = () => {
    mixpanel.track(buttonName, {
      userID: user?.id || "Anonymous",
    })
  }

  return trackButtonClick
}

/**
 * Tracks a button click with associated course data using Mixpanel.
 *
 * @param {string} buttonName - The name of the button being tracked.
 * @param {Course} course - The course object containing course metadata.
 * @returns {() => void} - A function that tracks the button click event along with course metadata.
 */
export function useMixpanelCourseTracker(
  buttonName: string,
  course: Course
): () => void {
  /**
   *
   */
  const trackButtonClick = () => {
    mixpanel.track(buttonName, {
      button: buttonName,
      courseID: course.id,
      tenantID: course.tenantID,
      courseTitle: course.title,
    })
  }

  return trackButtonClick
}

/**
 * Tracks the number of files uploaded using Mixpanel.
 * @returns {() => void} A function that tracks the file upload event.
 */
export function useMixpanelFileUploadTracker() {
  const { user }: any = useAuth()
  /**
   * Tracks number of files uploaded
   * @param numberOfFiles - Number of files in upload object
   */
  const trackFileUpload = (numberOfFiles: number) => {
    if (!numberOfFiles) return // No files to track

    mixpanel.track("File Upload", {
      numberOfFiles: numberOfFiles,
      userID: user?.id || "Anonymous",
    })
  }

  return trackFileUpload
}
