/**
 * @typedef {import("../remark.types").DocumentLocation} Location
 * @typedef {import("../remark.types").Remark} Remark
 * @typedef {import("../remark.types").Path} Path
 */

/**
 * Deterine the relative sort order of two values.
 */
const compare = (a, b) => (a < b ? -1 : a > b ? 1 : 0)

/**
 * Deterine the relative sort order of two paths.
 * @type {(a: Path, b: Path) => 1 | 0 | -1}
 */
const comparePaths = (a = [], b = []) => {
  if (!a.length && !b.length) {
    return 0
  }

  const [aHead = -1, ...aRest] = a
  const [bHead = -1, ...bRest] = b

  return compare(aHead, bHead) || comparePaths(aRest, bRest)
}

/**
 * Deterine the natural sort order of two document locations.
 *
 * @type {(a: Location, b: Location) => 1 | 0 | -1}
 */
const compareLocations = (a, b) =>
  compare(!!a, !!b) ||
  comparePaths(a?.path, b?.path) ||
  compare(a?.offset, b?.offset)

/**
 * Determine the relative sort order of two remarks.
 * @type {@type {(a: Location, b: Location) => 1 | 0 | -1}}
 */
const compareRemarks = (a, b) =>
  compareLocations(a.location, b.location) || compare(b.createdOn, a.createdOn)

export default compareRemarks
