import { Stack } from "@mui/material"
import RailButton from "./RailButton"
import {
  ArticleOutlined,
  ContactSupportOutlined,
  DashboardOutlined,
  DeleteOutline,
  FormatPaintOutlined,
  HelpCenterOutlined,
  Timeline,
  Translate,
} from "@mui/icons-material"
import LEAiLogo from "../../../assets/icons/LEAi_Logo_2024.svg"
import { useHistory } from "react-router"

/**
 * Component for side mounted navigation rail
 * @param props - Component props
 */
const NavigationRail = (props: any) => {
  const { location } = props
  const currentPath = location.pathname ?? "/"
  const history = useHistory()

  const railButtonProps = {
    /**
     * Handler for when nav button is clicked
     * @param path - Path to push onto history
     */
    onClick: (path: string) => {
      const search = path === "/history" ? "?page=0&limit=20" : ""
      history.push({ pathname: path, search })
    },
    currentPath,
  }

  return (
    <Stack
      paddingTop="8px"
      gap="12px"
      sx={{ width: "80px" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack justifyContent="center" alignItems="center">
        <img src={LEAiLogo} alt="LEAi Logo" width="42" height="42" />
      </Stack>
      <Stack gap="12px">
        <RailButton
          label="Courses"
          icon={<ArticleOutlined />}
          path="/"
          {...railButtonProps}
        />
        <RailButton
          label="Paths"
          icon={<Timeline />}
          path="/paths"
          {...railButtonProps}
        />
        <RailButton
          label="Branding"
          icon={<FormatPaintOutlined />}
          path="/branding"
          {...railButtonProps}
        />
        <RailButton
          label="Trash"
          icon={<DeleteOutline />}
          path="/trash"
          {...railButtonProps}
        />
        <RailButton
          label="Audit"
          icon={<DashboardOutlined />}
          path="/history"
          aliases={["/history-details"]}
          {...railButtonProps}
        />
        <RailButton
          label="Translations"
          icon={<Translate />}
          path="/translation-rules"
          {...railButtonProps}
        />
      </Stack>
      <Stack gap="12px">
        <RailButton
          label="Help"
          icon={<HelpCenterOutlined />}
          path="/help"
          {...railButtonProps}
        />
        <RailButton
          label="Support"
          icon={<ContactSupportOutlined />}
          path="/support"
          {...railButtonProps}
        />
      </Stack>
    </Stack>
  )
}

export default NavigationRail
