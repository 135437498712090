import { isArray } from "lodash"
import { Detection } from "../remark.types"
import { ServerDetection } from "../actions/fetchDetections"

/**
 * Normalize the structure of a detection or set of detections returned from the server.
 *
 * @param {ServerDetection | ServerDetection[]} data - The detection or set of detections to normalize.
 * @returns {Detection | Detection[]} The normalized detection or set of detections.
 */
function normalizeDetection(data: ServerDetection[]): Detection[]
function normalizeDetection(data: ServerDetection): Detection
function normalizeDetection(
  data: ServerDetection | ServerDetection[]
): Detection | Detection[] {
  if (isArray(data)) {
    return data.map((data) => normalizeDetection(data))
  }

  return {
    ...data,
    id: data.id,
    rule: data.rule,
    detectionKey: data.detection_key,
    text: data.text,
    selectedIndex: data?.transformation?.options ? 0 : null,
    optionsVisible: false,
    remarkType: "detection",
    location: {
      path: data?.path ?? [],
      offset: data?.start_index ?? 0,
      tag: data?.type,
      text: data?.text,
    },
  }
}

export default normalizeDetection
