import { useCallback, useEffect, useState } from "react"
import * as api from "../../../../../api"

/**
 * Hook for handling retrieving action log details
 * @param record - Currently selected action log
 */
const useActionLogRecordData = (record: any) => {
  const [open, setOpen] = useState<boolean>(false)
  const [loadingDetails, setLoadingDetails] = useState<boolean>(true)
  const [data, setData] = useState<any>(null)

  const fetchHistoryDetails = useCallback(
    (id) =>
      api.getHistoryDetails(id).then((res) => {
        return res.data
      }),
    []
  )

  /**
   * When the current record changes, clear the current data and open state
   */
  useEffect(() => {
    setOpen(false)
    setData(null)
  }, [record, record.id])

  /**
   * Retrieve all action details from server
   */
  useEffect(() => {
    if (data != null || !open) {
      return
    }
    setLoadingDetails(true)
    fetchHistoryDetails(record.history_id).then((data) => {
      if (data) {
        setData(data)
        setLoadingDetails(false)
      }
    })
  }, [data, fetchHistoryDetails, open, record, record.id])

  return {
    open,
    setOpen,
    loadingDetails,
    data,
  }
}

export default useActionLogRecordData
